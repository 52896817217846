import React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"


import {
  container,
  heroFont,
  nameFont
} from "../components/layout.module.css"

/*
      <StaticImage
          src="../images/digega-logo-ffffff.png"
          width={25}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="diGega logo"
          style={{ marginLeft: `1.45rem`, marginTop: `1rem`,}}
        />
*/

export default function Home() {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Oswald&family=Roboto&family=Bebas+Neue&display=swap" rel="stylesheet"/>
        <title>diGega | Home</title>
      </Helmet>
      <StaticImage
        src="../images/digega-logo-ffffff.png"
        width={25}
        quality={95}
        formats={["auto", "webp", "avif"]}
        alt="diGega logo"
        style={{ marginLeft: `2rem`, marginTop: `2rem`,}}
      />
      <div className={container}>
        <h1 className={heroFont}>
          Coming
        </h1>
        <h1 className={heroFont}>
          Fall 2022
        </h1>
        <p>
          At <span className={nameFont}>diGega</span>, we're building the next generation knowledge management platform with education being our first use case.  Our goal is to have you assimilate new (or forgotten) complex information within minutes.  We should have a waitlist by the summer.  In the meantime, feel free to visit us on <a href="https://www.linkedin.com/company/digega/">LinkedIn</a>.
        </p>
      </div>
    </>
  )
}
